:root {
--grey0:                      #000000;      /* rgba(151, 151, 151, 0) */
  --grey1:                      #808080;      /* rgba(151, 151, 151, 0) */
  --grey3: 						#7f7f7f;
  --grey4:                      #9ca2ac;
  --grey8:                      #e5e5e5;

  --greyBg:                     #f2f2f2;
  --greyCheck: 					#cdcdcd;

  --tSpeed:                     .3s;

  
  --bBg:                       #e5e5e5;
  --bBgh:                      #cccccc;
 
  --buttonDefault: 		       #4c4c4c;
  --buttonDefaulth: 	       #222222;
  --frame:                   rgba(128, 128, 128, 0.8)

}



@font-face{
  font-family:"Abitare Sans";
  src:url("/public/fonts/5262913/a5d7a5d2-ec30-4999-a280-92f163e169b7.eot?#iefix");
  src:url("/public/fonts/5262913/a5d7a5d2-ec30-4999-a280-92f163e169b7.eot?#iefix") format("eot"),
      url("/public/fonts/5262913/8a7eda06-48ab-4713-bddd-338da4dc23bd.woff2") format("woff2"),
      url("/public/fonts/5262913/85a05362-e054-4133-9f47-cbfd775e4ea6.woff") format("woff"),
      url("/public/fonts/5262913/a9ab7489-92ac-43ae-b3bf-1b4680641bca.ttf") format("truetype");
    font-weight: normal;
}
@font-face{
  font-family:"Abitare Sans";
  src:url("/public/fonts/5262329/e6d7dd47-a2c3-4484-879c-a099300d2051.eot?#iefix");
  src:url("/public/fonts/5262329/e6d7dd47-a2c3-4484-879c-a099300d2051.eot?#iefix") format("eot"),
      url("/public/fonts/5262329/9ab73c02-8713-4644-96a8-aa15405824b8.woff2") format("woff2"),
      url("/public/fonts/5262329/0ade9f4d-9801-4fb0-9f18-8c302c6c3bde.woff") format("woff"),
      url("/public/fonts/5262329/afbde9e1-9c08-47bd-9ea1-d7a440d8637f.ttf") format("truetype");
  font-weight: bold;
}




*                         {font-family: 'Abitare Sans'; letter-spacing: 0.01rem}

body, html, #root, #facadegenerator, .App, .layout-app  {height: 100%}
body                      {overflow:hidden}
/* #facadegenerator *        {box-sizing: content-box;} */


.content-container        {position: relative; height: calc(100% - 62px); border: 0px solid red; box-sizing: border-box}




.canvas-container   {width: 100%; height:100%; background-color: white; position: relative; border-top: 1px solid var(--grey4)} /* max-width: 1920px;  */
canvas              {width: 100%; height:100%; object-fit: cover}
img.base            {width: 100px; position: absolute; top:100px; left:0; z-index: 10}





.conf                           {width: 50px; height: 50px; border: 2px solid white; background: #f2f2f2 url(/public/assets/icon_configure.gif) center center no-repeat; border-radius: 50%;} 
.conf-wrap .slide               {max-width: 0px; position: absolute; left: 25px; z-index: -1; white-space: nowrap; height: 54px; top: 0; background: #f2f2f2; overflow: hidden; transition: all 0s; box-sizing: border-box; vertical-align: middle; user-select: none}
.conf-wrap .slide span          {padding: 20px 10px 0 36px; position: relative; display: block; user-select: none; font-size: 0.9rem}
.conf-wrap:hover .slide         {max-width: 180px; transition: all .5s ease-out; }
.conf-wrap                      {position: absolute; width: 50px; height: 50px; z-index: 1; cursor: pointer;}



button                      			 {background-color: var(--bBg); padding: 11px 13px; border: 0; font-weight: bold; cursor: pointer; margin-right: 7px; transition: all var(--tSpeed); color: black} 
button:hover                      		 {background-color: var(--bBgh) !important;}
button.right  							 {right:0; position: absolute; margin-right: 0px}
button, 
.sidebar .panel label       			 {font-size: 0.95rem} 
button.default,	button[type="submit"]	 {background-color: var(--buttonDefault); color: white; }
button.default:hover,	button[type="submit"]:hover	 {background-color: var(--buttonDefaulth);}

button.icon 							 {padding-left: 32px; background: none; background: url(/public/assets/icon_save.png) 2px 3px no-repeat; font-size: 0.8rem; border-radius: 3px}
button.icon:hover                        {background-color: var(--bBg) !important}
button.icon.delete 						 {background-image: url(/public/assets/icon_delete.png)}
button.icon.edit 						 {background-image: url(/public/assets/icon_edit.png); background-position: 3px 3px; padding-left: 22px; margin-left: 7px}
button.icon.load 						 {background-image: url(/public/assets/icon_load.png)}

.hidden 								  {display: none !important}


.scroller {overflow: auto}
.dialog h2                         		 {font-size:1rem; font-weight: normal; text-align: left;}
.decor 									 {vertical-align: top}
.decor img              	 			 {border: 1px solid var(--grey0); user-select: none; width: 69px; height: 69px}
.decor h3                  				 {font-size: 0.8rem; margin: 3px 0 3px 0 ; user-select: none; text-transform: uppercase;}
.decor p                       	 		 {font-size: 0.8rem; margin: 0; min-height: 60px;user-select: none}


.select-object                          {position: fixed; left: 50%; top: 50%; z-index: 20; width: 100%; max-width: 1150px; background: transparent; 
                                          padding: 14px;  translate: -50% -50%; opacity: 0; pointer-events: none;
                                          transition: all var(--tSpeed) ease-out; border: 1000px solid transparent;}
.select-object .inner                   {background: #fff; padding: 20px 70px; outline: 14px solid var(--frame)}
.select-object .inner .scroller         {padding-left: 6px}
.select-object .object                  {float: left; text-align: left; margin: 0 16px 0 0; max-width: 150px; cursor: pointer}
.select-object .object .wrap            {border: 6px solid rgba(151, 151, 151, 0); width: 150px; height: 150px; margin-left: -6px; user-select: none}
.select-object .object img              {width: 148px; border: 1px solid var(--grey1); user-select: none}
.select-object h2                       {font-size: 1.2rem; font-weight: normal; text-align: left}
.select-object h3                       {font-size: 0.8rem; margin-top: 7px; min-height: 40px; user-select: none}
.select-object .object .wrap.active     {border-color: rgba(151, 151, 151, 1)}
.select-object.active                   {opacity: 1; pointer-events: all; }
.select-object .closer 					{top: 18px; right: 20px} 


.select-decor                           {position: fixed; left: 50%; top: 50%; z-index: 20; width: 100%; max-width: 1050px; background: transparent; 
                                         padding: 14px; translate: -50% -50%; opacity: 0; pointer-events: none; border: 1000px solid transparent;
 										 transition: all var(--tSpeed) ease-out}
.select-decor .inner                    {background: #fff; padding: 3px 3px; outline: 14px solid var(--frame)}
.select-decor .decor                	{position: relative; float: left; text-align: left; margin: 0; max-width: 71px; padding: 8px; cursor: pointer}
.select-decor .decor.clicked 			{background: var(--grey3); color: white}
.select-decor .decor.clicked:before		{content: ""; position: absolute; background: var(--grey3) url(/public/assets/check-square.svg) 0 0 no-repeat; width: 32px; height: 32px; top: 0; right: 0; z-index: 10}
.select-decor .dialog h2 				{margin-left: 8px}
.select-decor .decor:hover              {text-decoration: underline}
.select-decor.active                    {opacity: 1; pointer-events: all;}
.select-decor button.default	 		{right:0; position: absolute; margin-right: 0}
.select-decor .footer 					{height: 42px; border-top: 1px solid var(--grey4); text-align: left; margin: 8px; padding-top: 8px; position: relative;}
.select-decor .closer 					{top: 18px; right: 20px}
.select-decor br.dist 					{margin-top: 17px}


.pager 									{position: absolute; right: 0; top: -44px; width: 100%;z-index: 10; background: white; padding-bottom: 10px; text-align: right}
.pager span 							{display: inline-block; font-size: 0.8rem; vertical-align: top; margin-top: 8px; margin-right: 14px}
.pager button.next,					
.pager button.previous					{display: inline-block; width: 32px; height: 32px; margin: 0 0 0 5px; position: relative;
										 background: var(--bBg) url(/public/assets/icon_page_next.png) 0 0 no-repeat; vertical-align: center}
.pager button.previous 					{background-image: url(/public/assets/icon_page_previous.png);}






.save-project 							{position: fixed; left: 50%; top: 50%; z-index: 20; width: 100%; max-width: 1050px; background: transparent; 
										padding: 14px; translate: -50% -50%; opacity: 0; pointer-events: none; border: 1000px solid transparent;
	 									transition: all var(--tSpeed) ease-out}
.save-project.active                    {opacity: 1; pointer-events: all;}
.save-project .inner                    {background: #fff; padding: 40px 0 12px 40px; outline: 14px solid var(--frame)}
.save-project .row                    	{text-align: left; border-top: 1px solid var(--grey4); margin-top: 28px; margin-right: 20px; position: relative}
.save-project .wrap                    	{margin-left: 270px; position: absolute; top:74px}
.save-project .footer 					{height: 42px; border-top: 1px solid var(--grey4); text-align: left; padding-top: 12px; margin-right: 40px; position: relative;}
.save-project .decor  					{display: inline-block; width: 200px;}	
.save-project .decor img 				{float:left; margin-right: 12px}
.save-project .row img  				{vertical-align: top}
.save-project .row .preview  			{margin: 0 50px 12px 0}
.save-project .dialog h2   				{}
.save-project .dialog .decor h3   		{margin: 2px 0 0 0 }
.save-project .dialog .decor p   		{margin-top: 0}
.save-project .dialog .row > span       {position: relative}
.save-project .dialog .row > h3   		{font-weight: normal; font-size:0.95rem; margin-bottom: 21px; display: inline-block;  }
.save-project .dialog .row > span input {background: var(--bBg); border: 0; font-size:0.95rem; padding:10px; margin: 18px 5px 18px  0}
.save-project .dialog .row > span input:focus-visible {outline: 0}
.save-project .dialog .row > span button.clear-text {background: url(/public/assets/input-clear.png) center center no-repeat; border: 0; position: absolute; width: 24px; height: 24px; overflow: hidden; padding: 0; margin:0; top: -1px; left: 180px}
.save-project .scroller 				{height: 400px;}



.product-list 							{position: fixed; left: 50%; top: 50%; z-index: 20; width: 100%; max-width: 1050px; background: transparent; 
										 padding: 14px; translate: -50% -50%; opacity: 0; pointer-events: none; border: 1000px solid transparent;
	 									 transition: all var(--tSpeed) ease-out}
.product-list.active                    {opacity: 1; pointer-events: all;}
.product-list .inner                    {background: #fff; padding: 30px 0 16px 20px; outline: 14px solid var(--frame)}
.product-list .scroller 				{height: 400px;}
.product-list table 					{width: calc(100% - 30px); border-collapse: collapse}
.product-list table tr					{}
.product-list table td 					{border: 0px solid #eee; text-align: left; vertical-align: top; padding: 20px 10px 20px 0; border-top: 1px solid var(--grey4)}
.product-list table th 					{font-weight: normal; text-align: left; font-size: 0.95rem; padding-bottom: 10px}
.product-list table img 				{width: 48px; height: 48px; float:left; margin-right: 10px}
.product-list td.decor h3				{font-size: 0.95rem;}
.product-list td.decor p				{min-height: auto; font-size: 0.95rem;}
.product-list a							{color: black; font-size: 0.95rem; text-decoration: none}
.product-list a.textlink				{margin: 30px 0 20px 0; display: block; text-decoration: underline; cursor: pointer}
.product-list .footer 					{height: 82px; text-align: left; padding-top: 12px; margin-right: 20px; position: relative; }
.product-list .footer a					{margin: 0}
.product-list .footer button.right		{bottom: 0}
.product-list .decor.mounting h3,
.product-list .decor.format h3,
.product-list .decor.product h3			{text-transform: none}
.product-list .producthint				{font-size: 0.7rem; width: 50%}
.product-list table td.decor.product    {max-width: 260px}
.product-list table td.decor.mounting    {max-width: 240px}







.in-shoppingcart                         {position: fixed; left: 50%; top: 50%; z-index: 25; width: 80%; max-width: 800px; background: transparent; 
    padding: 14px;  translate: -50% -50%; opacity: 0; pointer-events: none;
    transition: all var(--tSpeed) ease-out; border: 1000px solid transparent;}
.in-shoppingcart .inner                   {background: #fff; padding: 20px 20px; outline: 14px solid var(--frame); text-align: left}
.in-shoppingcart .closer 			     {top: 18px; right: 20px} 
.in-shoppingcart.active                  {opacity: 1; pointer-events: all; }


.shoppingcart-counter                    {border-radius: 10px; position: absolute; top: -4px; left: 40px; font-size: 0.7rem; padding: 3px 9px; text-align: center; background: var(--grey8); z-index: -1}




.help-layer 							{position: fixed; left: 50%; top: 50%; z-index: 20; width: 100%; height: 668px; max-width: 1050px; background: transparent; 
                                         padding: 0; translate: -50% -50%; opacity: 0; pointer-events: none; border: 1000px solid transparent;
                                         transition: all var(--tSpeed) ease-out}
.help-layer.active                      {opacity: 1; pointer-events: all;}
.help-layer .inner                      {background: #fff; outline: 14px solid var(--frame)}
.help-layer .scroller 				    {}
.help-layer .help                       {width: 100%;}
.help-layer .closer                     {position: absolute; top: 8.5%; right: 1%; width: 7%; height: 10%; background: rgba(255, 0, 0, 0.0); z-index: 1}
.help-layer .startbutton                {position: absolute; bottom: 10%; right: 6%; width: 22%; height: 10%; background: rgba(255, 0, 0, 0.0); z-index: 1; cursor: pointer}
/*
.help-layer .help.en                    {background: url(/public/assets/help/PFL_KO_FASSADE2223_Anleitungsgrafik_EN_LY01_Desktop.svg) 0 0 no-repeat;}
.help-layer .help.de                    {background: url(/public/assets/help/PFL_KO_FASSADE2223_Anleitungsgrafik_DE_LY01_Desktop.svg) 0 0 no-repeat;}
.help-layer .help.fr                    {background: url(/public/assets/help/PFL_KO_FASSADE2223_Anleitungsgrafik_FR_LY01_Desktop.svg) 0 0 no-repeat;}
.help-layer .help.it                    {background: url(/public/assets/help/PFL_KO_FASSADE2223_Anleitungsgrafik_IT_LY01_Desktop.svg) 0 0 no-repeat;}
.help-layer .help.cz                    {background: url(/public/assets/help/PFL_KO_FASSADE2223_Anleitungsgrafik_CZ_LY01_Desktop.svg) 0 0 no-repeat;}
.help-layer .help.nl                    {background: url(/public/assets/help/PFL_KO_FASSADE2223_Anleitungsgrafik_NL_LY01_Desktop.svg) 0 0 no-repeat;}
.help-layer .help.cn                    {background: url(/public/assets/help/PFL_KO_FASSADE2223_Anleitungsgrafik_CN_LY01_Desktop.svg) 0 0 no-repeat;}
*/
.help-layer .dummy-img                  {display: none}
.help-layer .stretcher                  {position: relative}
.help-layer .desktop-dummy-img          {display: block}



@media only screen and (max-width: 740px) { 
    .help-layer {}
    .help-layer .help                       {width: 100%; height: auto; position: relative;}
    /*.help-layer .help.en                    {background: url(/public/assets/help/PFL_KO_FASSADE2223_Anleitungsgrafik_EN_LY01_Mobil.svg) 0 0 no-repeat;}
    .help-layer .help.de                    {background: url(/public/assets/help/PFL_KO_FASSADE2223_Anleitungsgrafik_DE_LY01_Mobil.svg) 0 0 no-repeat;}
    .help-layer .help.fr                    {background: url(/public/assets/help/PFL_KO_FASSADE2223_Anleitungsgrafik_FR_LY01_Mobil.svg) 0 0 no-repeat;}
    .help-layer .help.it                    {background: url(/public/assets/help/PFL_KO_FASSADE2223_Anleitungsgrafik_IT_LY01_Mobil.svg) 0 0 no-repeat;}
    .help-layer .help.cz                    {background: url(/public/assets/help/PFL_KO_FASSADE2223_Anleitungsgrafik_CZ_LY01_Mobil.svg) 0 0 no-repeat;}
    .help-layer .help.nl                    {background: url(/public/assets/help/PFL_KO_FASSADE2223_Anleitungsgrafik_NL_LY01_Mobil.svg) 0 0 no-repeat;}
    .help-layer .help.cn                    {background: url(/public/assets/help/PFL_KO_FASSADE2223_Anleitungsgrafik_CN_LY01_Mobil.svg) 0 0 no-repeat;}
    */
    .help-layer .closer                     {top: 5.5%; right: 0; width: 13%; height: 2.5%;}
    .help-layer .startbutton                {bottom: 2%; right: 12%; width: 80%; height: 4%;}

    .help-layer .dummy-img {display: inline}
    .help-layer .desktop-dummy-img {display: none}
}






.clear {clear: both}
.closer                                    {position:absolute; width: 32px; height: 32px; z-index: 10; background: url(/public/assets/icon_close.png) center center no-repeat; cursor: pointer; right: 16px; top: 10px}



.message 							{position: fixed; left: 50%; top: 50%; z-index: 20; width: 60%; max-width: 600px; background: transparent; 
                                     padding: 14px; translate: -50% -50%; opacity: 1; border: 1000px solid transparent;
                                     transition: all var(--tSpeed) ease-out}
.message .inner                     {background: #fff; padding: 30px; outline: 14px solid var(--frame)}
.message .inner h2                  {font-weight: bold}
.message .inner p                   {font-size: 0.9rem; text-align: left}
.message .inner .footer             {border-top: 1px solid var(--grey4); padding-top: 20px}





.sidebar                                   {position: absolute; top: 0; right: 0; width: 500px; height: 100%; max-width: 50%; background: white; padding: 35px; padding-right: 0; text-align: left; opacity: 0; pointer-events: none; transition: opacity var(--tSpeed); 
                                            z-index: 2; box-sizing: border-box; overflow: auto}
.sidebar .inner 						   {position: relative; height: calc(100% - 100px); border: 0px solid red; overflow: hidden}
.sidebar.active                            {opacity: 1; pointer-events: all}
.sidebar .footer 						   {margin-top: 32px} 


.sidebar h2                                {font-weight: normal; font-size: 1.1rem; margin-bottom: 23px; user-select: none}
.sidebar ul.tabs                           {margin: 0; padding: 0; user-select: none}
.sidebar ul.tabs  li                       {border-top: 1px solid transparent; border-bottom: 1px solid transparent; list-style: none; float: left; padding: 5px 0px 5px 0px; margin-right: 25px; font-size: 0.9rem; font-weight: bold; text-transform: uppercase; cursor: pointer}
.sidebar ul.tabs  li.active                {border-top: 1px solid black; border-bottom: none}

.sidebar .settings                         {opacity: 0; pointer-events: none; transition: opacity var(--tSpeed); position: absolute; width: 100%; height: 100%}
.sidebar .settingstab                      {opacity: 0; pointer-events: none; transition: opacity var(--tSpeed); position: absolute; margin-top:30px; width: 100%; height: calc(100% - 110px); overflow:auto}   
.sidebar .settings.active,
.sidebar .settings.active .settingstab.active {opacity: 1; pointer-events: all;}


.sidebar .settings .pattern1               {width: 240px; height: 57px; background: url(/public/assets/pattern1.jpg) 0 0 no-repeat; left:0; top: 0; position: relative; margin-bottom: 18px}
.sidebar .settings .pattern2               {width: 240px; height: 57px; background: url(/public/assets/pattern2.jpg) 0 0 no-repeat; left:0; top: 0; position: relative; margin-bottom: 18px}
.sidebar .settings .pattern3               {width: 240px; height: 57px; background: url(/public/assets/pattern3.jpg) 0 0 no-repeat; left:0; top: 0; position: relative; margin-bottom: 18px}
.sidebar .settings .pattern4               {width: 240px; height: 57px; background: url(/public/assets/pattern4.jpg) 0 0 no-repeat; left:0; top: 0; position: relative; margin-bottom: 18px}
.sidebar .settings .settingstab.pattern label           {display: block; margin-bottom: 30px}
.sidebar .settings .pattern-preview {margin-bottom: 18px; user-select: none; border-left: 1px solid #979797; border-right: 1px solid #979797;}

.sidebar .panel                             {background: var(--greyBg); padding: 20px; margin-bottom: 20px; margin-right: 35px; position: relative;}
.sidebar .panel h3                          {font-weight: normal; font-size: 0.91rem; text-transform: none; font-weight: normal}
.sidebar .panel .decor                      {font-size: 0.95rem; margin-bottom: 5px}
.sidebar .panel .decor .tile                {width: 48px; height: 48px; background: var(--grey1); float:left; margin-right: 15px;}
.sidebar .panel .decor .tile img            {width: 100%; height: 100%}
.sidebar .panel .decor .articlenum          {font-weight: bold; padding-top: 8px; position: relative; text-transform: uppercase}
.sidebar .panel .decor .title               {padding: 0px}
.sidebar .panel .decor-rotate 				{user-select: none}

.sidebar .footer .decor-add                 {display: none}


.choose-language                    {position: absolute; z-index: 20; right: 0; top: 62px; background: var(--grey8); width: 420px; opacity: 0; transition: opacity var(--tSpeed); pointer-events: none}
.choose-language  table             {border-collapse: collapse; margin: 0 50px} 
.choose-language  td, a             {text-align: left; font-size: 0.9rem; text-decoration: none; color: black; user-select: none; cursor: pointer} 
.choose-language  td:first-child    {font-weight: bold; text-align: right} 
.choose-language  td                {border-bottom: 1px solid #ccc; padding: 16px 8px 16px 8px; vertical-align: top} 
.choose-language.visible 		    {opacity: 1; pointer-events: all}



.sidebar .panel.transparent 		{background: none}
.sidebar .panel h3 					{font-weight: normal; margin-bottom: 10px}
.sidebar .panel label 				{position: absolute;}
.sidebar .panel label.l1			{right: 20px}
.sidebar .panel .MuiSlider-root		{margin-top: 20px;}

.sidebar .panel h3.hslider 			{margin-top: 25px; font-weight: bold;}

.slidergroup 						{padding-right: 10px}
.slidergroup.disabled 				{opacity: 0.3}




/* mounting */
.mounting .productimage 			{width: 179px; height: 120px; display: inline-block; vertical-align: top; position: relative;}
.mounting .drawing 					{width: 191px; height: 104px; display: inline-block; margin-right: 40px; position: relative; top: -12px; left: 10px}
.mounting .wrap   	            {border: 0px solid red;max-height: 140px; margin-bottom: 40px}
.mounting .productimage.m1   	{background: url(/public/assets/m1.webp) 0 0 no-repeat}
.mounting .drawing.m1   		{background: url(/public/assets/m1.svg) 0 0 no-repeat}
.mounting .productimage.m2   	{background: url(/public/assets/m2.webp) 0 0 no-repeat}
.mounting .drawing.m2   		{background: url(/public/assets/m2.svg) 0 0 no-repeat}
.mounting .productimage.m3   	{background: url(/public/assets/m3.webp) 0 0 no-repeat}
.mounting .drawing.m3   		{background: url(/public/assets/m3.svg) 0 0 no-repeat}
.mounting .productimage.m4   	{background: url(/public/assets/m4.webp) 0 0 no-repeat}
.mounting .drawing.m4   		{background: url(/public/assets/m4.svg) 0 0 no-repeat}
.mounting label span            {width: 220px;}
.mounting label.radio           {position: relative; top: -40px}









.image-slider                       {position: absolute; top: 0; right: 0; width: 100%; height:100% !important; background: white; opacity: 0; pointer-events: none; transition: opacity var(--tSpeed); 
									 z-index: 8; box-sizing: border-box; overflow: auto; text-align: left; left: -1000px}
.image-slider.active                {opacity: 1; pointer-events: all; left: 0}									
.image-slider .compare-box 					{position: absolute; top: 0; right: 0; transition: all 0.2s; width: 200px; height: 100%; max-height: calc(100% - 0px); z-index: 10 ; background: white; padding: 28px; padding-right: 0; overflow: hidden;  padding-top: 6px; box-sizing: border-box; border-bottom: 1px solid var(--grey8)}
.image-slider .compare-box .scroller        {height: calc(100% - 12px); overflow-y: auto; position: relative; padding-right: 28px}
.image-slider .compare-box.close			{max-height: 22px; }
.image-slider .compare-box .preview 		{width: 143px; user-select: none; cursor: pointer; position: relative}
.image-slider .compare-box .preview .marker {width: 143px; height: 86px; box-sizing: border-box; top:20px; position: absolute}
.image-slider .compare-box .preview.active .marker {border: 4px solid #4b4b4b}
.image-slider .compare-box .preview img 	{width: 100%; border: 1px solid var(--grey1); box-sizing: border-box}
.image-slider .compare-box .comparinfo      {font-size: 0.8rem; margin-top: 20px}
.image-slider .compare-box 	.closer         {top: 2px; right: 6px}
.image-slider .compare-box h2 				{font-size: 1.2rem; font-weight: normal; margin: 0px 0 18px 0; user-select: none; padding-left: 20px; position: relative; left: -20px; background: url(/public/assets/arrow-down.png) 0 6px no-repeat; cursor: pointer}
.image-slider .compare-box.close h2	        {background: url(/public/assets/arrow-right.png) 0 6px no-repeat}
.image-slider .compare-box h3 				{font-size: 0.9rem; font-weight: normal; margin: 16px 0 4px 0; user-select: none}
.image-slider .compare-box:first-child h3 	{margin-top: 0}
.image-slider .__rcs-handle-line 			{width: 4px !important}
.image-slider .__rcs-handle-button  		{top: -10px !important; display: block !important; width: 140px !important; height: 100px !important; 
											 background: url(/public/assets/compare-arrows.png) center center no-repeat !important; border: 0px solid red !important;
											border-radius: 0 !important; backdrop-filter: none !important; position: absolute; box-shadow: none !important;}
.image-slider  .__rcs-handle-button div 	{display: none} 



.App div.info      {background: yellow; font-size: 0.8rem; position: absolute; z-index: 100; left: 0; top: 200px; border: 1px solid black; padding: 5px}



.App              {text-align: center;}
.App-logo         {height: 40vmin; pointer-events: none;}
.App-header       {background-color: #282c34; min-height: 100vh; display: flex; flex-direction: column; align-items: center; justify-content: center; font-size: calc(10px + 2vmin); color: white;}
.App-link         {color: #61dafb;}









.loader 				    {opacity: 1; transition: all 3s; position: fixed; width: 100%; height: 100%;  top: 0; background: white; pointer-events: none; z-index: 1000; }
.loader.hidden 			    {opacity: 0}
.loader > div				{top: calc(50% - 50px); left: calc(50% - 50px); position: absolute;}
.loader .spinner-container  {position: relative; width: 100px; height: 100px; animation: spin 1s infinite linear; background: url(/public/assets/spinner.svg) center center no-repeat; background-size: 50%;}
 
.loader div	span		    {vertical-align: middle; border: 0px solid red; display: inline-block; padding-left: 10px; top: -12px; position: relative;}
    @keyframes spin {
        from  {transform: rotate(0deg)} 
        to    {transform: rotate(360deg)}
    }


.spinner 				    {opacity: 0; transition: all 1s; left: 50%; top: 50%; translate: -50% -50%; ; position: fixed; pointer-events: none; z-index: 1000; outline: 1px solid var(--frame); width: 100px; height: 100px; background: white;}
.spinner.visible 		    {opacity: 1;}
.spinner > div				{position: relative}
.spinner .spinner-container {position: relative; left: 14px; width: 70px; height: 70px; animation: spin 1.5s infinite linear; background: url(/public/assets/spinner.svg) center center no-repeat; background-size: 50%; text-align: center}

.spinner div span	        {color: black; vertical-align: middle; border: 0px solid red; display: inline-block; top: -10px; position: relative; white-space: nowrap}





header 						{border-bottom: 1px solid var(--grey8); z-index: 10; position: relative; height: 60px;} 
header .bar			        {margin: 0; position: relative;}
header .bar li 				{list-style: none; float:left; display: inline-block; border: 0px solid red; vertical-align: middle}

a.pfleiderer-logo img       {width: 166px}
a.duropal-logo    img       {width: 59px; margin-top: 8px}
a.thermopal-logo  img       {width: 51px; margin-top: 5px}
a.wcs-logo        img       {width: 48px; margin-top: 1px}

header .logobar  			{padding-left: 14px; position: relative; top: 20px}
header .logobar li 			{padding: 0 14px 0 13px; height: 30px; background: url(/public/assets/tick.gif) 0 3px no-repeat;}
header .logobar li:first-child {background: none; padding-left: 0; padding-right: 25px}


header .menubar				{position: relative; top: 13px}
header .menubar	li			{font-size: 0.8em; font-weight: bold; text-transform: uppercase; user-select: none}
header .menubar	li a		{font-size: 0.9em; font-weight: bold; text-transform: uppercase; padding: 13px 10px 13px 10px; cursor:pointer; position: relative; display: inline-block}

.buttonbar 				{position: absolute; right: 0; top: 7px; margin: 0; padding:0; z-index: 11}
.buttonbar li			{background: url(/public/assets/icons.svg) 0 0 no-repeat; background-size: 120px 480px; float:left; position: relative; height: 30px; list-style: none; cursor:pointer; min-width: 60px}
.buttonbar li a			{padding-top: 32px; display: inline-block; font-size: 0.66rem; user-select: none; letter-spacing: 0}

.buttonbar .shoppingcart    {background-position: center -15px}
.buttonbar .compare         {background-position: center -75px}
.buttonbar .save            {background-position: center -135px}
.buttonbar .print           {background-position: center -195px}
.buttonbar .fullscreen      {background-position: center -255px}
.buttonbar .contact         {background-position: center -315px}
.buttonbar .help            {background-position: center -375px}
.buttonbar .lang            {background-position: center -435px}



/* Slider */
.MuiSlider-root                                         {width: calc(100% - 5px) !important;}
.MuiSlider-rail 										{color: var(--grey1); opacity: 1 !important;}
.MuiSlider-track										{display: none !important;} 					/* nicht aktiven Bereich abdunkeln */
.MuiSlider-thumb 										{color: var(--grey1) !important;}
.css-eg0mwd-MuiSlider-thumb:hover, 
.css-eg0mwd-MuiSlider-thumb.Mui-focusVisible   			{box-shadow: 0px 0px 0px 8px rgba(151, 151, 151, 0.2) !important}
.css-eg0mwd-MuiSlider-thumb:hover, .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible {box-shadow: none !important; }
.css-7drnjp:hover                                       {box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 8px !important;}




/* Custom radio */



label.radio input[type="radio"] ~ span {
  align-items: center; cursor: pointer; display: block;  xxxflex-flow: row nowrap; position: relative; padding-left: 40px; position: relative; width: 180px
}

label.radio input[type="radio"] ~ span::before {
	background-color: #ccc;
	content: "";
	display: block;
	width: 30px;
	height: 30px;
	margin-right: 9px;
	-webkit-mask-image: url(/public/assets/radio-inactive.svg);
    mask-image: url(/public/assets/radio-inactive.svg);
    position: absolute; 
    top: calc(50% - 15px); 
    left: 0; 

  }

label.radio input[type="radio"]:checked ~ span::before {
    background-color: #7f7f7f;
    content: "";
	display: block;
	width: 30px;
	height: 30px;
    margin-right: 9px;
    -webkit-mask-image: url(/public/assets/radio-active.svg);
    mask-image: url(/public/assets/radio-active.svg);
}
label.radio input[type="radio"] {
	box-shadow: unset;
	left: -10000px;
	position: absolute;
}
input[type="checkbox"], input[type="radio"] {
	box-sizing: border-box;
	padding: 0;
}






.checkbox {
	left: -9000px;
	position: absolute;
	width: 34px;
}
.checkbox ~ label.forcheck {
	margin-left: 87px;
	position: relative;
  }
.checkbox ~ label.forcheck::before {
	display: flex;
	justify-content: center;
	content: "";
	align-items: center;
	background-color: var(--greyCheck);
	border-radius: 2px;
	box-shadow: inset 3px 3px 3px 0 rgba(0,0,0,.03);
	height: 26px;
	top: -5px;
	left: -33px;
	width: 26px;
	position: absolute;
}


.checkbox:checked ~ label.forcheck::before {
	background-color: #7f7f7f;
	content: url("data:image/svg+xml;charset=utf-8,%3Csvg width='12' height='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.59 0 4 6.59 1.41 4.01 0 5.42l4 4 8-8z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
}


.footerlink             {font-size: 0.95rem; text-decoration: underline; top: -20px; position: relative; margin-bottom: 0px; display: block;}
.footerlink.invisible   {visibility: hidden}






@media only screen and (max-width: 1240px) { 
	.content-container       		    {height: calc(100% - 142px); }
	.sidebar 							{height: 50%; width: 100%; max-width: 100%; top: 50%}
    .sidebar .inner                     {height: calc(100% - 60px);}
    .sidebar .panel                     {margin-right: 0;}
	.buttonbar 							{bottom: 32px; top: auto; width: 100%; right: -5px}
	.buttonbar li						{margin-left: calc(6.3% - 30px); margin-right: calc(6.3% - 30px); min-width: 54px}

	.App.has-sidebar .content-container 	{height: 100%}
	.App.has-sidebar .canvas-container      {height: 50%}
	.App.has-sidebar header 				{display: none}
	.App.has-sidebar .buttonbar 		    {display: none}
	.App.has-sidebar .sidebar				{padding: 12px; padding-right: 0}
	.App.has-sidebar .sidebar .settingstab  {margin-top: 10px; height: calc(100% - 70px)}

	.App.has-sidebar .sidebar ul.tabs li.active {border-bottom: 1px solid black; border-top: none;}
	.App.has-sidebar .sidebar h2 				{margin: 6px 0 10px 0; user-select:none}
	.App.has-sidebar .sidebar .closer 			{top: 3px; right: 8px}
	.App.has-sidebar .sidebar .panel h3 		{margin-top: 0}


    .App.has-sidebar .sidebar .panel .decor-edit-group    {border: 0px solid red; position: absolute; width: 300px; height: 100px; top: 0; right: 0px}
	.App.has-sidebar .sidebar .panel .decor-rotate 	{position: absolute; width: 130px; right: 12px; top: 50px; outline: 0px dotted red}
	.App.has-sidebar .sidebar .panel .decor-change  {position: absolute; width: 130px; right: 12px; outline: 0px dotted red; margin-top: 0; padding: 0; margin-right: 0; background: none; text-align: left; text-decoration: underline; top: 80px; }
	

	.App.has-sidebar .sidebar .settings 	{width: calc(100% - 12px)}
	.App.has-sidebar .sidebar ul.tabs  li   {margin-right: 12px;}
	.App.has-sidebar .sidebar ul.tabs  li:last-child 		   {margin-right: 0}

	.App.has-sidebar .sidebar .footer 						   {text-align: right; margin-right:22px; margin-top: 12px; } 
	
	.select-object 					{height: 100%; width: 100%; translate: none; left:0; top: 0; padding: 0; border:0}
	.select-object .inner 			{position: relative; margin: 14px; top: 50%; translate: 0% -50%; outline-width: 200px; height: calc(100% - 100px); overflow: hidden; padding: 20px}
	.select-object .inner .scroller 	{height: calc(100% - 100px); overflow: auto; position:absolute}


	.save-project 					{height: 100%; width: 100%; translate: none; left:0; top: 0; padding: 0; border:0}
	.save-project .inner 			{position: relative; margin: 14px; top: 50%; translate: 0% -50%; outline-width: 200px; height: calc(100% - 100px); overflow: hidden; padding: 20px; padding-right: 0}
	.save-project .decor img 		{width: 48px; height: 48px;}
	.save-project .decor 			{width: 120px;}
	.save-project .row .preview 	{margin: 0 20px 12px 0; width: 150px }
	.save-project .scroller 		{height: calc(100% - 150px); overflow: auto; padding-right: 0}


	.product-list 					{height: 100%; width: 100%; translate: none; left:0; top: 0; padding: 0; border:0}
	.product-list .inner 			{position: relative; margin: 14px; top: 50%; translate: 0% -50%; outline-width: 200px; height: calc(100% - 100px); overflow: hidden; padding: 20px;  padding-right: 0}
	.product-list .scroller 		{height: calc(100% - 150px); overflow: auto;}

	.select-decor                   {height: 100%; width: 100%; translate: none; left:0; top: 0; padding: 0; border:0}
	.select-decor .inner  			{position: relative; margin:0 14px; top: 50%; translate: 0% -50%; outline-width: 200px; height: calc(100% - 80px); overflow: hidden; padding: 20px;  padding-right: 0}
	.select-decor .scroller 		{height: calc(100% - 110px); overflow: auto;}




}


@media only screen and (max-width: 740px) { 
	header 								{height: 94px;} 
	.content-container       		    {height: calc(100% - 181px); }
	header .menubar 					{position: absolute; right: 10px; top: 50px;}
	xxxheader .logobar li:last-child 		{background: none;}
    .App.has-sidebar .sidebar .settingstab .decor-add     {display: none}
    .App.has-sidebar .sidebar .footer .decor-add    {display: block; margin-top: 0; padding: 0; background: none; text-align: left; text-decoration: underline; position: absolute; left: 12px; bottom: 30px}
    .save-project .wrap                             {position: relative; margin-left: 0; top: 0}


    .help-layer 					{height: 100%; width: 100%; translate: none; left:0; top: 0; padding: 0; border:0}
    .help-layer .inner 			    {position: relative; margin: 14px; top: 50%; translate: 0% -50%; outline-width: 200px; height: calc(100% - 100px); overflow: hidden; padding-right: 0}
    .help-layer .scroller 		    {height: 100%; overflow: auto; border: 0px solid red; position: relative}
    .buttonbar li                   {margin-left: calc(6.5% - 30px); margin-right: calc(6.5% - 30px);}
    .buttonbar li a                 {text-align: center; max-width: 80px;}

}


@media only screen and (max-width: 500px) { 
    .buttonbar li a                 {font-size: 0.55rem; min-width: 57px;}
    header .logobar li 			    {padding: 0 9px 0 9px;}
    a.pfleiderer-logo img           {width: 150px; margin-top: 3px}
    a.duropal-logo    img           {width: 50px; margin-top: 8px}
    a.thermopal-logo  img           {width: 40px; margin-top: 7px}
    a.wcs-logo        img           {width: 44px; margin-top: 2px}
    header .logobar li:first-child  {padding-right: 12px}
    .mounting .productimage 	    {width: 160px; height: 100px;}
}

@media only screen and (max-width: 400px) { 
    header .menubar li a            {padding: 13px 5px 13px 5px;}
}
       



















@media only print { 
	.buttonbar, .sidebar, .image-slider, .conf-wrap, .select-object, .select-decor, .save-project   {display: none}
    .textlink.order-samples   {display: none !important}
	
	.product-list {
		display: block; 
		opacity: 1;
		position: absolute;
		width: 100%;
		max-width: auto;
		left: 0;
		top: 35%; 
		translate: none;
		border: 0;
		padding: 0;
		margin-top: 40px; 
		transition: none;
	}
	.product-list .scroller 			{overflow: visible; height: auto} 	
	.product-list td 					{min-width: 120px}
	.product-list td.product			{min-width: 230px}
    .product-list td.format			    {width: 90px; min-width: 90px;}
	.product-list p, .product-list h3 	{font-size: 0.8em !important}
	.content-container 					{height: 35%; width: 100%}
	.product-list .inner 				{outline: none}

	header 								{padding-bottom: 12px; margin-bottom: 12px; border-bottom: 1px solid var(--grey4)}
	header .bar.menubar,
	.product-list button.right,	
	.product-list h2  					{display: none}

	header .bar.logobar 				{left: -40px}
	.pfleiderer-logo 					{display: block; position: absolute; right: -40px}

    .product-list .decor img {
        border: 2px solid var(--grey0);
    }

}